import React, { useState } from 'react';
import axios from 'axios';
import './contact.css';
import Footer from '../footer/footer';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    number: '',
    info: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData.name.trim() === '' ||
      formData.surname.trim() === '' ||
      formData.email.trim() === '' ||
      formData.number.trim() === '' ||
      formData.info.trim() === ''
    ) {
      alert('Please fill the form.');
      return;
    }

    axios
      .post('https://belij01.pythonanywhere.com/form', formData)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
        alert('Error sending email: ' + error.response.data.message); // Improved error handling
      });
  };

  return (
    <div className='cont__wrap'>
      <div className='contact__wrap' data-aos="fade-up">
        <h1>Contact Us</h1>
        <p>Your success is our mission</p>
        <div className='contact__inputs'>
          <form onSubmit={handleSubmit}>
            <div className='input-group'>
              <label htmlFor='name'>First Name</label>
              <textarea
                name='name'
                id='name'
                value={formData.name}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className='input-group'>
              <label htmlFor='surname'>Last Name</label>
              <textarea
                name='surname'
                id='surname'
                value={formData.surname}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className='input-group'>
              <label htmlFor='email'>E-Mail</label>
              <textarea
                name='email'
                id='email'
                value={formData.email}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className='input-group'>
              <label htmlFor='number'>Phone Number</label>
              <textarea
                name='number'
                id='number'
                value={formData.number}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className='input-group'>
              <label htmlFor='info'>Describe what you would like to implement?</label>
              <textarea
                name='info'
                id='info'
                className='info'
                value={formData.info}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type='submit'>Send</button>
          </form>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Contact;
