import React, { useState, useEffect, useRef } from 'react';
import './home.css';
import img from './Scene 01 2.png';
import smallimg from './im1.png';
import { Link, useLocation } from 'react-router-dom';
import About from '../about/about';
import Footer from '../footer/footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ChatBotComponent  from './ChatBot';


function Home() {
    const [imageSrc, setImageSrc] = useState(img);
    const [showExtraButton, setShowExtraButton] = useState(false);
    const [isChatBotVisible, setChatBotVisible] = useState(false); 
    const aboutRef = useRef(null);
    const location = useLocation();
    useEffect(() => {
        AOS.init({
          duration: 1000,
          once: false,
        });
      }, []);
      const handleScroll = () => {
        const section = document.getElementById('About');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };
      const closeChatBot = () => {
        setChatBotVisible(false);
    };
    const handleBotClick = () => {
        console.log("Clicked");
        setChatBotVisible(true);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 720) {
                setImageSrc(smallimg);
                setShowExtraButton(true);
            } else {
                setImageSrc(img);
                setShowExtraButton(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (location.state && location.state.fromHeader) {
            if (aboutRef.current) {
                aboutRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <div className='gen__wrap'>
            <div className='home__wrap' data-aos="fade-up">
                <div className='home__text'>
                    <h1>WE WILL TURN YOUR IDEAS INTO DIGITAL REALITY</h1>
                    <p>White Web Works</p>
                    <div className='home__btns'>
                        <button onClick={handleScroll} id='about_bttn'><a>About Us</a></button>

                        <button><Link to="/services">Our Services</Link></button>
                        {/* Show extra button based on condition */}
                        {showExtraButton && <button onClick={() => aboutRef.current.scrollIntoView({ behavior: 'smooth' })}>About</button>}
                    </div>   
                </div>
                <div className='home__img'>
                    <img src={imageSrc} alt="Home Image" className='img__home' />
                </div>
            </div>
            <div>
                <About />
            </div>
            <Footer/>
            <ChatBotComponent isVisible={isChatBotVisible} onClose={closeChatBot} />
        </div>
    );
}

export default Home;